import React from "react";
import useDownloader from "react-use-downloader";
import reportIcon from "../../../styles/svg/report-icon.svg";
import downloadIcon from "../../../styles/svg/download-records.svg";
import { downloadReport } from "../../../sevices/jobApi";

function ReportDownload({ params, idClient = false }) {
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

  const downloadFile = () => {
    downloadReport(params.row.job_id)
      .then((res) => {
        console.log(res);
        download(
          `${
            process.env.REACT_APP_BASE_URL
          }${`${res.data.data.file_url}`.replaceAll("#", "%23")}`,
          res.data.data.file_name
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <img
        src={idClient ? downloadIcon : reportIcon}
        className="cursor-pointer"
        onClick={downloadFile}
      />
    </>
  );
}

export default ReportDownload;
