import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RedFlag from "../../../../../styles/svg/red-unit.svg";
import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import DefaultServiceIcon from "../../../../../styles/svg/default-service-icon.svg";
import CustomTable from "../../../../../components/Table/CustomTable";
import Loader from "../../../../../components/Loader";
import { useCustomParams } from "../../../../../hooks/useCustomParams";
import { servicesVariables } from "../../../../../utils/constants";
import useDownloader from "react-use-downloader";
import { downloadReport } from "../../../../../sevices/jobApi";
import ViewDocument from "../RecentUploads/ViewDocument";
import Schedule from "./Schedule";
import moment from "moment";

function Table({
  responseData,
  totalRecords,
  isLoading,
  isError,
  error,
  detail,
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [search] = useCustomParams("search");
  const redirect = useNavigate();

  const { download } = useDownloader();

  const downloadFile = (id) => {
    downloadReport(id)
      .then((res) => {
        console.log(res);
        download(
          `${process.env.REACT_APP_BASE_URL}${res.data.data.file_url}`,
          res.data.data.file_name
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "unit_number",
      headerName: "#Unit",
      renderCell: (params) => {
        return (
          <p
            className="unit-ticket cursor-pointer"
            onClick={() => redirect(`unit/${params.row.unit_id}`)}
          >
            {params.value}
          </p>
        );
      },
      minWidth: 100,
      flex: 1,
      // editable: true,
      // sortable: false,
      cellClassName: (value) => {
        console.log("param", value.row.is_exempt);
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "building_address",
      headerName: "Building",
      renderCell: (params) => {
        return <p className="">{detail?.address}</p>;
      },
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        console.log("param", value.row.is_exempt);
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "job_data",
      headerName: "Report On File",
      renderCell: (params) => {
        const completedJobs = params?.value.filter(
          (el) =>
            el.report_status != "Pending" && el.report_status != "No Access"
        );
        let obj = {};
        for (let i = 0; i < completedJobs.length; i++) {
          let job = completedJobs[i];
          if (job.service_id && job?.attachment) {
            if (
              job?.report_status == "Negative" ||
              job?.report_status == "Positive"
            ) {
              if (servicesVariables.xrfEnitrUnitNegative == job.service_id) {
                if (!Object.hasOwn(obj, "xrfEntireUnit")) {
                  obj.xrfEntireUnit = job;
                }
              } else if (
                servicesVariables.xrfFrictionPositive == job.service_id
              ) {
                if (!Object.hasOwn(obj, "xrfFrinction")) {
                  obj.xrfFrinction = job;
                }
              }
            }
          }
        }
        return (
          <div className="w-100 d-flex justify-content-center">
            {obj?.xrfEntireUnit ? (
              obj?.xrfEntireUnit.report_status == "Negative" ? (
                <ViewDocument
                  jobId={obj?.xrfEntireUnit.job_id}
                  icon={GreenFlag}
                />
              ) : obj?.xrfEntireUnit.report_status == "Positive" ? (
                <ViewDocument
                  jobId={obj?.xrfEntireUnit.job_id}
                  icon={RedFlag}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {obj?.xrfFrinction ? (
              obj?.xrfFrinction.report_status == "Negative" ? (
                <ViewDocument
                  jobId={obj?.xrfFrinction.job_id}
                  icon={frictionGreen}
                />
              ) : obj?.xrfFrinction.report_status == "Positive" ? (
                <ViewDocument
                  jobId={obj?.xrfFrinction.job_id}
                  icon={frictionRed}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {/* {params?.value?.service_id && params.row?.job_data?.attachment ? (
              params?.value?.service_id ==
                servicesVariables.xrfEnitrUnitNegative &&
              params.row.job_data.report_status == "Negative" ? (
                <ViewDocument
                  jobId={params.row.job_data.job_id}
                  icon={GreenFlag}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfEnitrUnitPositive &&
                params.row.job_data.report_status == "Positive" ? (
                <ViewDocument
                  jobId={params.row.job_data.job_id}
                  icon={RedFlag}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfFrictionPositive &&
                params.row.job_data.report_status == "Positive" ? (
                <ViewDocument
                  jobId={params.row.job_data.job_id}
                  icon={frictionRed}
                />
              ) : params?.value?.service_id ==
                  servicesVariables.xrfFrictionNegative &&
                params.row.job_data.report_status == "Negative" ? (
                <ViewDocument
                  jobId={params.row.job_data.job_id}
                  icon={frictionGreen}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )} */}
          </div>
        );
      },
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Report On File</p>
        </div>
      ),
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: "center-header",
      cellClassName: (value) => {
        console.log("param", value.row.is_exempt);
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "schedule",
      headerName: "Actions",
      renderCell: (params) => {
        return <Schedule params={params} />;
      },
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        console.log("param", value.row.is_exempt);
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
    {
      field: "pending-job",
      headerName: "Pending Ticket",
      renderCell: (params) => {
        const pendingJobs = params?.row?.job_data
          .filter((el) => el.report_status == "Pending")
          .filter((el) => moment(el.scheduled_date).isAfter(moment(), "day"));
        return (
          <p className="d-flex">
            {pendingJobs?.length > 0
              ? pendingJobs.map((el, idx) => (
                  <p>
                    {`${idx > 0 ? ", " : ""}`}
                    {moment(el?.scheduled_date).format("MM/DD/YYYY")}
                  </p>
                ))
              : ""}
          </p>
        );
      },
      minWidth: 110,
      flex: 1,
      // editable: true,
      sortable: false,
      cellClassName: (value) => {
        console.log("param", value.row.is_exempt);
        return value.row.is_exempt ? "highlighted_row" : "";
      },
    },
  ];
  console.log("responseData of data having is_exempt ", responseData);
  const filteredUnits = search
    ? responseData?.filter((item) =>
        item.unit_number.toLowerCase().includes(search.toLowerCase())
      )
    : responseData;

  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <CustomTable
            initialRows={filteredUnits}
            columns={columns}
            defaultSort={"unit_number"}
            headerBgColor={"#F5F5F9"}
            rowCount={totalRecords}
            setPaginationModel={(data) => {
              setPage(data.page);
              setPageSize(data.pageSize);
            }}
            page={page}
            pageSize={pageSize}
            subHeight={282}
            hideFooter={true}
            useMaxHeight={true}
          />
        </>
      )}
    </>
  );
}

export default Table;
