import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";

function CustomTable({
  initialRows,
  columns,
  headerBgColor,
  defaultSort,
  setPaginationModel,
  rowCount,
  subHeight,
  hideFooter = false,
  borderRadius = true,
  sort = "asc",
  onSortModelChange = () => {},
  isLoading = false,
  useMaxHeight = false,
  ...rest
}) {
  const [rows, setRows] = useState([""]);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (selectionModel) => {
    const newSelectedRow = selectionModel[0];
    if (newSelectedRow != null && !selectedRows.includes(newSelectedRow)) {
      setSelectedRows([...selectedRows, newSelectedRow]);
      setRows(
        rows.map((row) =>
          row.id === newSelectedRow ? { ...row, disabled: true } : row
        )
      );
    }
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 == 0
      ? "inactive-row-table"
      : "active-row-table";
  };

  console.log("useMaxHeight", useMaxHeight);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScrollerRenderZone, & .MuiDataGrid-virtualScrollerContent":
              {
                ...(useMaxHeight
                  ? { maxHeight: `calc(96vh - ${subHeight}px) !important` }
                  : { height: `calc(96vh - ${subHeight}px) !important` }),

                overflowY: "auto",
              },
            // "& .MuiDataGrid-overlayWrapperInner": {
            //   height: `calc(96vh - ${subHeight}px) !important`,
            // },
            // "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            //   borderRight: `1px solid #CED4DA !important`,
            // },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              // borderBottom: `1px solid #CED4DA !important`,
            },
            // "&.active-row ": {
            //   backgroundColor: "lightgreen",
            // },

            // "& .inactive-row": {
            //   backgroundColor: "#EDEDED",
            // },
            "& .active-row-table": {
              background: "#fbfbff",
            },
            "& .highlighted_row": {
              backgroundColor: "#8fdf82",
            },
            "& .MuiDataGrid-topContainer::after": {
              backgroundColor: "transparent", // Disable the background color
            },
            "& .MuiDataGrid-row": {
              maxHeight: "56px !important",
              minHeight: "56px !important",
              height: "56px !important",
              //   border: "1px solid #E5E7EC",
              borderBottom: "1px solid #E5E7EC",
              "--rowBorderColor": "transparent",
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },

            "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row.disabled": {
              pointerEvents: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              //   borderRight: "1px solid rgba(224, 224, 224, 1)",
              //   fontSize: isMobile ? "10px !important" : "14px !important",
              fontSize: "14px",
              color: "#637381 !important",
              display: "flex",
              alignItems: "center",
              fontFamily: "Segoe UI Regular",

              flexWrap: "wrap",
              height: "auto",
              minHeight: "inherit",
              maxHeight: "inherit",
              lineHeight: "20px",
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              //   height: isMobile ? "30px !important" : "",
              backgroundColor: headerBgColor ? headerBgColor : "#F5F5F9",
              //   fontSize: isMobile ? "12px" : "",
              // color: "#455F69",
              color: "#1B3E61",
              fontSize: "14px",
              fontFamily: "segoe_uisemibold",
              // borderRight: '1px solid rgba(224, 224, 224, 1) !important',
              //   paddingLeft: "0px",
              // paddingRight: "0px",
              height: "44px  !important",
              // borderBottom: "1px solid #CED4DA",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              //   height: isMobile ? "30px !important" : "",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              //   fontWeight: isMobile ? "400 !important" : "500 !important",
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-columnGroupHeader": {
              //   border: "1px solid #CED4DA",
              backgroundColor: "#F5F8FC !important",
              color: "#fff",
              outline: "none",

              //   fontSize: isMobile ? "10px" : "20px",
            },
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              //   paddingLeft: isMobile ? "5px" : "10px",
              //   margin: isMobile ? "20px 5px !important" : "",
              // width: "100%",
            },

            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainerContent":
              {
                margin: "auto !important",
                paddingLeft: "0px",
              },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
              {
                borderBottomWidth: "1px !important",
                backgroundColor: headerBgColor ? headerBgColor : "#F5F5F9",
                // height: isMobile ? "50px !important" : "100px !important",
                // borderBottom: "1px solid #CED4DA",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-columnHeader--filledGroup": {
              //   height: isMobile ? "150px !important" : "100px !important",
              //   maxHeight: isMobile ? "50px !important" : "100px",
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitle":
              {
                fontWeight: "700 !important",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-filler": {
              height: "0px !important",
            },
            "& .MuiTablePagination-displayedRows": {
              //   fontSize: isMobile ? "10px !important" : "",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "hidden !important", // Hides the vertical scrollbar
            },

            "& .MuiDataGrid-scrollbar--vertical": {
              overflowY: "hidden !important", // Hides the vertical scrollbar
              display: "none !important",
            },
            "& .MuiDataGrid-scrollbarFiller": {
              display: "none !important",
            },
            "& .MuiDataGrid-main": {
              // border: "1px solid #CED4DA", // Apply border to the table
              border: "1px solid #E5E7EC",
              borderTop: "0px",
              //   borderRight: 0,
              borderRadius: borderRadius ? "0px 0px 8px 8px" : "0px",
            },
            "& .MuiDataGrid-footerContainer": {
              border: "none", // Ensure the footer has no border
            },
            "& .MuiDataGrid-overlayWrapper": {
              //   top: isMobile ? "0px !important" : "",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          },
        },
      },
    },
  });

  const CustomNoRowsOverlay = () => {
    return (
      <Stack>
        <Typography variant="subtitle1">No data found</Typography>
      </Stack>
    );
  };

  const CustomLoadingOverlay = () => (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Box>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box
            style={{
              width: "100%",
              //   height: initialRows.length > 0 ? "" : isMobile ? "" : "319px",
              // overflowX: "hidden",
              // height:'50vh'
            }}
          >
            <DataGrid
              rows={initialRows}
              getRowClassName={getRowClassName}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick={true}
              onSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              paginationMode="server"
              // slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              selectionModel={selectedRows}
              // getRowClassName={(params) =>
              //   params.row.disabled ? "MuiDataGrid-row disabled" : ""
              // }
              rowCount={rowCount}
              initialState={{
                pagination: {
                  paginationModel: { ...rest },
                },
                sorting: {
                  sortModel: [{ field: defaultSort, sort }],
                },
              }}
              autoHeight
              rowHeight={56}
              pageSizeOptions={[10, 20, 50, 100]}
              onPaginationModelChange={setPaginationModel}
              onSortModelChange={onSortModelChange}
              disableExtendRowFullWidth
              checkboxSelection={false}
              hideFooterSelectedRowCount
              hideFooterPagination={hideFooter}
              sx={{
                border: 0,
              }}
              loading={isLoading}
            />
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
}

export default CustomTable;

// flex-wrap: wrap;
//     height: auto;
//     min-height: inherit;
//     max-height: inherit;
//     line-height: 20px;
//     white-space: normal;
