import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UnitDetail from "./UnitDetail";
import Heading from "./Heading";
import { useBuildingsById } from "../../../../../reactQuery/building";
import GlobalSearch from "../../../../../components/Search";
import Table from "./Table";
import { useErrorBoundary } from "react-error-boundary";
import BackButton from "../../../../../components/BackButton";

function BuildingDetail() {
  const param = useParams();
  const { showBoundary } = useErrorBoundary();
  const {
    detail,
    isLoading: detailLoading,
    responseData,
    totalRecords,
    isError,
    error,
    refetch,
  } = useBuildingsById(param.buildingId);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      showBoundary(error);
    }
  }, [error]);

  return (
    <div>
      <div className="d-flex justify-content-space-between mb-4">
        <div></div>
        <BackButton />
      </div>
      {param?.clientId && (
        <UnitDetail detail={detail} detailLoading={detailLoading} />
      )}
      <Heading detail={detail} />
      <div className="tickets-filter d-flex justify-content-space-between mt-4">
        <GlobalSearch />
      </div>
      <Table
        responseData={responseData}
        totalRecords={totalRecords}
        isLoading={detailLoading}
        isError={isError}
        error={error}
        detail={detail}
      />
    </div>
  );
}

export default BuildingDetail;
