import moment from "moment";
import ReportDownload from "../../../../Tickets/components/ReportDownload";
import RedFlag from "../../../../../styles/svg/red-unit.svg";
import GreenFlag from "../../../../../styles/svg/green-unit.svg";
import frictionRed from "../../../../../styles/svg/red-friction.svg";
import frictionGreen from "../../../../../styles/svg/green-friction.svg";
import ViewDocument from "./ViewDocument";
import { servicesVariables } from "../../../../../utils/constants";

export const columns = [
  {
    field: "building_address",
    headerName: "Building",
    renderCell: (params) => {
      return <p className="">{params.value}</p>;
    },
    minWidth: 110,
    flex: 1.5,
    // editable: true,
  },

  {
    field: "apartment",
    headerName: "Unit",
    renderCell: (params) => {
      return <p className="unit-ticket">{params.value}</p>;
    },
    minWidth: 100,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },

  {
    field: "service_type",
    headerName: "Type",
    renderCell: (params) => {
      return <p>{params.value}</p>;
    },
    minWidth: 130,
    flex: 1.2,
    // editable: true,
    sortable: false,
  },
  {
    field: "scheduled_date",
    headerName: "Date",
    renderCell: (params) => {
      return <p>{moment(params.value).format("MM/DD/YYYY")}</p>;
    },
    minWidth: 120,
    flex: 1,
    // editable: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Actions",
    renderCell: (params) => {
      return (
        <div className="d-flex">
          <ViewDocument params={params} />
          {/* <ReportDownload params={params} idClient={true} /> */}
        </div>
      );
    },
    minWidth: 70,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
  {
    field: "service_id",
    headerName: "Result",
    renderCell: (params) => {
      console.log(params.value);
      return (
        <div className="d-flex">
          <p>
            {params?.value ? (
              params?.value == servicesVariables.xrfEnitrUnitNegative &&
              params?.row?.report_status == "Negative" ? (
                <img src={GreenFlag} />
              ) : params?.value == servicesVariables.xrfEnitrUnitPositive &&
                params?.row?.report_status == "Positive" ? (
                <img src={RedFlag} />
              ) : params?.value == servicesVariables.xrfFrictionPositive &&
                params?.row?.report_status == "Positive" ? (
                <img src={frictionRed} />
              ) : params?.value == servicesVariables.xrfFrictionNegative &&
                params?.row?.report_status == "Negative" ? (
                <img src={frictionGreen} />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </p>
        </div>
      );
    },
    minWidth: 70,
    flex: 0.7,
    // editable: true,
    sortable: false,
  },
];
