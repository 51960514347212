import React, { useEffect, useState } from "react";
import { columns } from "./tableColumns";
import CustomTable from "../../../components/Table/CustomTable";
import Loader from "../../../components/Loader";
import { useCustomParams } from "../../../hooks/useCustomParams";
import { useClient } from "../../../reactQuery/client";

function Table() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [search] = useCustomParams("search");
  const [sort, setSort, removeSort] = useCustomParams("sort");
  const [status] = useCustomParams("status");

  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    isRefetching,
  } = useClient({
    page: page + 1,
    pageSize,
    search,
    pagination: true,
    sort: sort
      ? `&sort_by=${sort.split("-")[1]}&sort=${sort.split("-")[0]}`
      : "",
    status: status ? `&status=${status}` : "",
  });
  useEffect(() => {
    refetch();
  }, [page, pageSize, search, sort, status]);

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0];
      setSort("sort", `${field}-${sort}`);
    } else {
      removeSort("sort");
    }
  };
  console.log("error :client", error);
  return (
    <div className="client-table-page">
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>{error?.response?.data?.message || "Error"}</p>
        </div>
      )}
      {!isLoading && !isError && (
        <CustomTable
          initialRows={responseData}
          columns={columns}
          headerBgColor={"#F5F5F9"}
          rowCount={totalRecords}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          subHeight={302}
          sort={sort ? sort.split("-")[1] : "asc"}
          defaultSort={sort ? sort.split("-")[0] : "company_name"}
          onSortModelChange={handleSortModelChange}
          isLoading={isRefetching}
        />
      )}
    </div>
  );
}

export default Table;
