import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Service from "./Service";
import useCustomMutate from "../../../hooks/useMutate";
import { updateSequence } from "../../../sevices/serviceApi";

function DragableList({ services }) {
  const [characters, updateCharacters] = useState([]);
  const { mutate } = useCustomMutate(updateSequence, ["service"]);

  useEffect(() => {
    updateCharacters(services || []);
  }, [services]);

  console.log(characters, services);
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    mutate({
      sequence_list: items.map((el, idx) => {
        return {
          service_id: el.service_id,
          sequence: idx + 1,
        };
      }),
    });
    updateCharacters(items);
  }
  return (
    <div style={{ width: "100%" }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div key={"1list"} id="1list">
          <Droppable droppableId="1list">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dragableList"
              >
                {characters?.map((item, index) => (
                  <Draggable
                    key={`services-${item.service_id}`}
                    draggableId={`services-${item.service_id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Service item={item} />
                        </Grid>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
}

export default DragableList;
