import React, { useState } from "react";
import {
  useBuildings,
  useBuildingsById,
} from "../../../../../reactQuery/building";
import PrimaryButton from "../../../../../components/Button/Button";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import { Container } from "@mui/material";
import TicketForm from "../../../../Tickets/components/TicketForm";
import { useParams } from "react-router-dom";
import { formatPhoneNumber } from "../../../../../utils/helpers";

function Schedule({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const param = useParams();
  const { responseData: data } = useBuildings();
  const {
    detail,
    isLoading: detailLoading,
    responseData,
    totalRecords,
    isError,
    error,
    refetch,
  } = useBuildingsById(param.buildingId);

  const [formData, setFormData] = useState({
    scheduled_date: "",
    service_id: "",
    building_id: param.buildingId,
    apartment: params.row.unit_number,
    unit_id: params.row.unit_id,
    unit_status: "",
    unit_size: params.row.unit_size,
    borough: detail?.borough,
    superintendent_name: detail?.superintendent_name,
    superintendent_number: formatPhoneNumber(detail?.superintendent_number),
    additional_comment: "",
    is_flexible: false,
  });

  console.log("paramsparams", params);

  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <div>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Create Ticket"}
          btnTitle={"Confirm"}
        >
          <Container>
            <div className="mb-32">
              <p className="schedule-appointment-heading">
                Schedule Appointment
              </p>
              <p className="form-instruction">
                Please complete all fields to schedule an appointment for
                service.
              </p>
            </div>
            <TicketForm
              initialValues={formData}
              onClose={handleClose}
              isFromClient={params.clientId}
            />
          </Container>
        </LongFormDialog>
      )}
      <PrimaryButton
        variant="contained"
        className={`capitalize btn-bg-orange`}
        onClick={() => setAnchorEl(true)}
      >
        Schedule
      </PrimaryButton>
    </div>
  );
}

export default Schedule;
