import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import BackButton from "../../../../../components/BackButton";

function Heading({ detail, address }) {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          className="d-flex align-items-center justify-content-space-between"
        >
          <Grid item xs={4} md={4}>
            {address && <p className="page-heading-name">{address}</p>}
            <p className="page-heading-service">{`${detail?.[0]?.unit_size} - ${detail?.[0]?.unit_number}`}</p>
          </Grid>
          <BackButton />
        </Grid>
      </Box>
    </div>
  );
}

export default Heading;
