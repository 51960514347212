import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { useClientById } from "../../../reactQuery/client";
import ClientDetails from "./components/ClientDetails";
import Building from "./components/Building";
import Table from "./components/Building/Table";
import GlobalSearch from "../../../components/Search";
import PendingTable from "./components/PendingTickets/Table";
import RecentTable from "./components/RecentUploads/Table";
import NoAccessTable from "./components/Noaccess/Table";
import PendingIcon from "../../../styles/svg/pending-table.svg";
import RecentIcon from "../../../styles/svg/recent-upload-table.svg";
import RightArrow from "../../../styles/svg/right-arrow-icon.svg";

function ClientDetail() {
  const redirect = useNavigate();
  const params = useParams();
  const role = JSON.parse(localStorage.getItem("userData")).role;

  const viewAll = (status) => {
    redirect(`tickets?status=${status}`);
  };
  return (
    <div>
      {params?.clientId && <ClientDetails />}
      <Building />
      <div className="tickets-filter d-flex justify-content-space-between mt-4">
        <GlobalSearch />
      </div>
      <Table />
      <Grid container spacing={4} sx={{ marginTop: "36px" }}>
        <Grid item xs={12} md={6}>
          <div className="tickets-filter d-flex  align-items-center justify-content-space-between">
            <div className="d-flex">
              <img src={PendingIcon} />
              <p className="heading-title-name ml-2">Pending Tickets</p>
            </div>
            {role == "client" && (
              <div
                className="d-flex cursor-pointer"
                onClick={() => viewAll("Pending")}
              >
                <p className="view-all">View All</p>
                <img src={RightArrow} />
              </div>
            )}
          </div>
          <PendingTable />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="tickets-filter d-flex align-items-center justify-content-space-between">
            <div className="d-flex">
              <img src={RecentIcon} />
              <p className="heading-title-name ml-2">Recent Uploads</p>
            </div>
            {role == "client" && (
              <div
                className="d-flex cursor-pointer"
                onClick={() => viewAll("Recently Uploaded")}
              >
                <p className="view-all">View All</p>
                <img src={RightArrow} />
              </div>
            )}
          </div>
          <RecentTable />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="tickets-filter d-flex  align-items-center justify-content-space-between">
            <div className="d-flex">
              <img src={PendingIcon} />
              <p className="heading-title-name ml-2">No Access</p>
            </div>
            {role == "client" && (
              <div
                className="d-flex cursor-pointer"
                onClick={() => viewAll("No Access")}
              >
                <p className="view-all">View All</p>
                <img src={RightArrow} />
              </div>
            )}
          </div>
          <NoAccessTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default ClientDetail;
